import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { signalrService } from 'services/signalr.service';
import { checkUserRole } from 'shared/utils/commonUtils';
import { toastTreeGenerationStatus } from 'shared/utils/toastUtils';
import { USER_PATH } from 'shared/constants/routesConstants';
import { setTreeVersionStatus } from 'store/treeVersion/treeVersion.actions';

export const useDecisionTreeGenerationListener = (
  currentUser: DTO.CurrentUser | null
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAiAdmin = checkUserRole(currentUser?.user_type).aiAdmin;
  const runListener = !!currentUser?.user_id && isAiAdmin;

  useEffect(() => {
    if (runListener) {
      const connection = signalrService.getConnection({
        url: `generateTree`,
        isNeedOnlyAmeEnv: true
      });
      const start = async () => {
        try {
          await connection.start();
          connection.on(
            'GetNotificationMessage',
            (msg: DTO.WSTreeVersionGeneration) => {
              dispatch(
                setTreeVersionStatus({
                  tree_id: msg.tree_id,
                  tree_version_id: msg.tree_version_id,
                  status: msg.task_status
                })
              );
              toastTreeGenerationStatus(msg, () => {
                history.push(USER_PATH.TREE, msg);
              });
            }
          );
          await connection.invoke('Subscribe').catch(() => {});
        } catch (err) {
          console.error('Connection failed: ', err);
        }
      };
      start();

      return () => {
        const stop = async () => {
          try {
            await connection.stop();
          } catch (err) {
            console.error('Disconnection failed: ', err);
          }
        };
        stop();
      };
    }

    return () => {};
  }, [dispatch, history, runListener]);
};
