import React, { FC } from 'react';
import { Route, RouteProps } from 'react-router';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { selectCurrentUser } from '../../store/selectors/userSelectors';
import { USER_PATH } from '../../shared/constants/routesConstants';
import { USER_TYPES } from '../../shared/constants/commonConstants';

interface Props extends RouteProps {
  accessRoles?: DTO.UserType[];
  from?: string;
  to?: string;
}

export const PrivateRoute: FC<Props> = ({ accessRoles = [], ...rest }) => {
  const user = useSelector(selectCurrentUser);

  if (!accessRoles.includes(user.user_type) && accessRoles.length) {
    return (
      <Redirect
        to={
          user.user_type === USER_TYPES.MEMO_AI_ADMIN
            ? USER_PATH.TREE
            : USER_PATH.MEMO
        }
      />
    );
  }

  return <Route {...rest} />;
};
