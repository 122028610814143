import { AxiosPromise } from 'axios';

import { axiosService } from '../axios.service';

class MemoAITreeApi {
  static getManagementAiTrees(): Promise<DTO.AiTree[]> {
    return axiosService
      .instance({
        url: 'ai/admin/trees/management'
      })
      .then(({ data }) => data);
  }

  static getAiTrees(): Promise<DTO.AiTree[]> {
    return axiosService
      .instance({
        url: 'ai/admin/trees'
      })
      .then(({ data }) => data);
  }

  static createAiTree(tree: DTO.AiTreeCreationData) {
    return axiosService
      .instance({
        method: 'post',
        url: 'ai/admin/tree',
        data: tree
      })
      .then((response) => response.data);
  }

  static updateAiTree(tree: DTO.AiTree) {
    const treeData = {
      tree_id: tree.tree_id,
      tree_name: tree.tree_name,
      tree_transaction_type: tree.tree_transaction_type,
      tree_description: tree.tree_description,
      tree_is_active: tree.tree_is_active,
      tree_created_timestamp: tree.tree_created_timestamp
    };

    return axiosService
      .instance({
        method: 'put',
        url: 'ai/admin/tree',
        data: treeData
      })
      .then(({ data }) => data);
  }

  static deleteAiTrees(trees: DTO.AiTree[]): Promise<DTO.AiTree[]> {
    return axiosService
      .instance({
        url: 'ai/admin/tree',
        method: 'DELETE',
        data: [...trees.map((tree) => tree.tree_id)]
      })
      .then(({ data }) => data);
  }

  static bulkChangeAiTreeActiveStatus(
    data: DTO.BulkChangeTreeActiveStatusParams
  ): AxiosPromise<void> {
    return axiosService.instance({
      method: 'post',
      url: 'ai/admin/tree/activate',
      data
    });
  }

  static getAITreeVersionsByTreeId(
    treeId: string
  ): Promise<DTO.AiTreeVersion[]> {
    return axiosService
      .instance({
        url: `ai/admin/tree/${treeId}/version`
      })
      .then(({ data }) => data);
  }

  static createMemoAiTreeVersion(treeId: string) {
    return axiosService
      .instance({
        method: 'post',
        url: `ai/admin/tree/${treeId}/version`
      })
      .then((response) => response);
  }

  static deleteMemoAiTreeVersion(treeVersionId: string) {
    return axiosService
      .instance({
        method: 'delete',
        url: `ai/admin/tree/version/${treeVersionId}`
      })
      .then((response) => response);
  }

  static exportAiTrees(): AxiosPromise<Blob> {
    return axiosService.instance({
      url: 'ai/admin/trees/export',
      responseType: 'blob'
    });
  }
}

export default MemoAITreeApi;
