import { AI_APP_NAME } from 'shared/constants/commonConstants';

const resources = {
  en: {
    translations: {
      countries: 'Countries',
      'select country': 'Select Country',
      'select countries': 'Select Countries',
      'all countries': 'All Countries',
      'all trees': 'All Trees',
      'all creators': 'All Creators',
      'all users': 'All Users',
      'all accessible trees': 'All Accessible Trees',
      add: 'Add',
      info: 'info',
      'additional info': 'Additional Information',
      'additional info checkbox': 'Include All Additional Info',
      'add memo': 'Create Memo',
      'create new version': 'Create New Version',
      'activate version': 'Activate Version',
      'deactivate version': 'Deactivate Version',
      'remove version': 'Remove Version',
      'version status failed':
        'Something went wrong. Please remove the failed version and create a new one.',
      create: 'Create',
      'add node': 'Add Node',
      deleteNode: 'Delete Node',
      deleteMemo: 'Delete Memo',
      restoreMemo: 'Restore Memo',
      saveNode: 'Save Node',
      'accounting standard': 'Accounting Standard',
      'transaction type': 'Transaction Type',
      transactionType: 'Transaction Type',
      'accounting standard administration': 'Accounting Standards',
      'transaction types': 'Transaction Types',
      'feature administration': 'Features',
      'settings administration': 'Settings',
      'feature type': 'Feature type',
      type: 'Type',
      'country(s)': 'Country(s)',
      'last updated date': 'Last updated date',
      'add disable': 'Add / Disable',
      edit_ai_disabled: `Editing is not an option for the Transaction Type. To add a new Transaction Type, visit the ${AI_APP_NAME} Administration page.`,
      'add standard': 'Add an Accounting Standard',
      'add transaction': 'Add Transaction Type',
      'add transaction type btn': 'Add Transaction Type',
      'download report': 'Download Report',
      'add standard btn': 'Add Standard',
      'start date': 'Start date',
      'end date': 'End date',
      'add tree': 'Add an accounting standard',
      'add user to memo': 'Add user to memo',
      'confirmation update': 'Confirmation update',
      'create new version description':
        'Are you sure you want to create new version?',
      'de/activate ai version description':
        'Are you sure you want to {{ process }} this version?',
      'disabled create new version description':
        'To enable the control, remove failed decision tree version',
      'disabled upload excel in version tree description':
        'To enable the control add 1 version',
      'remove version description':
        'Are you sure you want to remove version <strong>{{ versionNumber }}</strong>?',
      'still active tree':
        'The {{ tree }} <strong>{{ names }}</strong> {{ verb }} still active. Please deactivate {{ trees }} before deleting.',
      'delete tree confirm':
        'Are you sure you want to delete {{ tree }} <strong>{{ names }}</strong>? If you delete {{ verb }}, data will be completely deleted from the system.',
      delete: 'Delete',
      activate: 'Activate',
      deactivate: 'Deactivate',
      generate_from_open_ai: 'Generate from Open AI',
      upload_excel: 'Upload Excel',
      download_excel: 'Download Excel',
      'all approved memos': 'All Approved Memos',
      'all memos': 'All Memos',
      approver: 'Approver',
      assigned: 'Assigned',
      approverName: 'Approver name',
      answer: 'Answer',
      answers: 'Answers',
      'approval notes': 'Approval Notes',
      approve: 'Approve',
      'choose approver': 'Choose approver',
      'choose new user': 'Choose New User',
      'share with new user': 'Share with new user',
      approved: 'Approved',
      approves: 'Approved',
      'approve memo': 'Approve Memo',
      back: 'Back',
      apply: 'Apply',
      'reset filters': 'Reset filters',
      access: 'Access',
      cancel: 'Cancel',
      'check if relevant': '(Select if Relevant)',
      'close node panel':
        'Please save or cancel changes to the selected node first.',
      conclusion: 'Conclusion',
      continue: 'Continue',
      'create memo': 'Create Memo',
      count: 'Count',
      description: 'Description',
      destination: 'Destination',
      disable: 'Disable',
      'document title': 'Document Title',
      download: 'Download',
      upload: 'Upload',
      files: 'Files',
      'upload files': 'Upload files',
      'unsupported files': 'Unsupported file type',
      'only one file': 'Only one file upload allowed',
      remove: 'Remove',
      proceed: 'Proceed',
      share: 'Share',
      edit: 'Edit',
      'edit tree': 'Edit Tree',
      'empty additional info': 'Some additional information is missing.',
      'empty edge': 'Answer or destination cannot be empty.',
      'empty node text': 'Empty node cannot be saved.',
      'error add': 'Could not add.',
      'error approve': 'Could not approve.',
      'error create': 'Could not create.',
      'error delete': 'Could not delete.',
      'error generate': 'Could not generate memo.',
      'error finalize': 'Could not finalize.',
      'error memo share': 'Memo has already been shared with this user.',
      'error save': 'Could not save.',
      'error submit': 'Could not submit.',
      'error update': 'Could not update.',
      'error upload': 'Could not upload file.',
      'exist three node':
        'There are more than 3 unconnected nodes, please edit them before adding new nodes.',
      export: 'Export',
      'file format not valid': 'File format not valid.',
      'file list for memo': 'File list for memo',

      import: 'Import',
      industry: 'Industry',
      key: 'Key',
      text: 'text',
      'info edit title': 'Edit Additional Info',
      link: 'Link',
      'log out': 'Log out',
      'memo already approved': 'Memo already approved',
      'memo already sent for approval': 'Memo already sent for approval.',
      'memo info': 'Memo Information',
      'memo name': 'Memo Name',
      'enter memo name': 'Enter Memo Name',
      'enter client name': 'Enter Client Name',
      comment: 'Comment',
      'previous question': 'PREVIOUS QUESTION',
      'save and continue': 'SAVE & CONTINUE',
      'select standard': 'Select Standard',
      'select version': 'Select version',
      'add counties': 'Add countries',
      'Add countries from another version or standard':
        'Add countries from another version or standard',
      'tree version': 'Tree Version',
      'select tree version': 'Select Tree Version',
      'select industry': 'Select Industry',
      include: 'Include',
      'no description': 'No description',
      'version name exists':
        'This version name you uploaded already exists, please enter a different version name.',
      'version name': 'Version name',
      'version name placeholder': 'Enter new version name',
      'memo note':
        'Note:  When saving the memo in the tool, do not include any client identifiers.',
      'memo name contain special char':
        'Memo name cannot contain special characters',
      'memo name empty': 'Memo name is empty.',
      'memo name exist': 'Memo name already exists.',
      'draft name exist':
        'Draft name already exists, please choose another one',
      'version number': 'Version {{ versionNumber }}',
      'delete draft confirm':
        'Are you sure you want to delete the {{name}} draft?',
      'delete draft': 'Delete Draft',
      saveAsDraft: 'Save as Draft',
      saveAsNewVersion: 'Save as New Version',
      name: 'Name',
      selectConclusionNode:
        'The conclusion node cannot be empty if conclusion is enabled.',
      'name or description empty': 'Name or description cannot be empty.',
      'new note': 'New Note',
      'no additional info': 'No additional info exists for this node.',
      'no data': 'No Data',
      'no results found': 'No results found',
      'no file chosen': 'No file chosen.',
      'no industry selected': 'No Industry selected.',
      'no client name chosen': 'No client name chosen',
      'client name': 'Client Name',
      'node info': 'Node Information',
      'no notes': 'No note exists for this memo.',
      rationale: 'Rationale',
      markAsRationale: 'Mark as Rationale',
      notes: 'Notes',
      'add note': 'ADD NOTE',
      'add note message': 'Note was successfully added',
      'empty note message': 'Cannot add empty note',
      'notes placeholder': 'Leave comment here',
      'not submitted': 'Not Submitted',
      'no user found': 'No user found.',
      'no user exists': 'User not found',
      'no email selected': 'No email selected',
      'no user selected': 'No user selected.',
      'no user share': 'This memo is not being shared with anyone yet.',
      'one active tree version at least':
        'There should be at least one active tree version.',
      pending: 'Pending',
      completed: 'Completed',
      deleted: 'Deleted',
      'in progress': 'In Progress',
      'question / conclusion': 'Question / Conclusion',
      question: 'Question',
      questions: 'Questions',
      comments: 'Comments',
      questionnaire: 'Questionnaire',
      'relevant info': 'Relevant information',
      reject: 'Reject',
      rejected: 'Rejected',
      'reject memo': 'Reject Memo',
      requestor: 'Requestor',
      save: 'Save',
      confirm: 'Confirm',
      F: 'The version {{ treeName }} contains draft edits that were not saved as the new version. Do you want to proceed working on this version or discard changes?',
      'save draft': 'Save Draft',
      'save and download': 'Save and Download',
      'save changes': 'Save Changes',
      'save unavailable': 'Save unavailable',
      'memo has been marked complete':
        'This memo has been marked complete and it cannot be saved',
      'go to summary': 'Go to Summary page',
      'memo updated': 'Memo updated',
      'overwrite memo': 'Overwrite memo',
      'memo was updated':
        'The Memo was updated by <0>{{ userName }}</0> at <0>{{ saveDate }}.</0> <1/> Go back to the "Summary page" to view the updates or “Overwrite the memo”',
      'discard and leave': 'Discard & Leave',
      'modified info':
        'You have modified this work item. You can save your changes, discard your changes, or cancel to continue editing.',
      'save changes node': 'Do you want to save your changes for this node?',
      section: 'Section',
      select: 'Select',
      'select file': 'Select File',
      'select user': 'Select User',
      'scan failed': 'Scan Failed',
      'replace file': 'replace file',
      search: 'Search',
      'search by email': 'Search by email',
      'select standard title':
        'Please select a standard to begin generating a new memo:',
      close: 'Close',
      shareMemo: 'Share Memo',
      'similar memo for memo': 'Similar memos for Memo',
      'similar memo': 'Similar Memo',
      submit: 'Submit',
      'submit new tree': 'Submit a New Tree',
      'sure submit tree': 'Are you sure you want to submit this tree?',
      'success shared': 'Successfully Shared',
      'success approve': 'Successfully Approved.',
      'success create': 'Successfully Created.',
      'success delete': 'Successfully Deleted.',
      'success finalize': 'Successfully Finalized.',
      'success save': 'Successfully Saved.',
      'success submit': 'Successfully Submitted.',
      'success update': 'Successfully Updated.',
      'success upload': 'Successfully Uploaded.',
      'success upload with scan': 'Processing the uploaded file(s)',
      'sure approve': 'Are you sure you want to approve this memo?',
      'sure reject': 'Are you sure you want to reject this memo?',
      summary: 'Summary',
      'summary conclusion disclaimer':
        'Note: Practitioners are responsible for considering all facts and\n' +
        'circumstances and exercising professional judgment to reach and\n' +
        'document an appropriate conclusion. The following sample text may be\n' +
        'appropriate based on the answers provided, but the practitioner is\n' +
        'responsible for reaching an appropriate conclusion and complying with\n' +
        'all applicable professional standards, and this sample language should\n' +
        'be replaced or revised as necessary.',
      'summary conclusion': 'Conclusion. ',
      'disclaimer on memo file': 'Disclaimer on Memo Output File',
      title: 'Title',
      total: 'Total',
      'total memos': 'Total Memos',
      'tree desc': 'Tree Description',
      'tree creator': 'Tree Creator',
      aq_tree: 'AQ Tree',
      'tree name': 'Tree Name',
      'tree name exist': 'Tree name exists.',
      'tree not valid': 'Tree not valid.',
      'delete tree': 'Delete tree',
      'uncheck irrelevant': '(Uncheck if irrelevant)',
      'update user': 'Update User',
      'user administration': 'Users',
      all: 'All',
      'users amount': '{{amount}} users',
      'grant access': 'Grant access',
      'edit access': 'Edit access',
      addTreeAccess: 'Add Tree Access',
      'user type': "Users's types",
      'select trees': 'Select trees',
      'trees dropdown label': 'Tree(s)',
      'created date': 'Created Date',
      'accessible trees': 'Accessible Trees',
      'approval date': 'Approval Date',
      'last updated': 'Last Updated',
      updatedBy: 'by {{name}}',
      'deleted date': 'Deleted Date',
      email: 'Email',
      'user name': 'User Name',
      users: 'Users',
      'upload memo file': 'Upload Memo File',
      'upload supporting docs': 'Upload Supporting Documents',
      'download supporting docs': 'Download Supporting Documents',
      'select file type': 'Select File Type',
      'download memo file': 'Download Memo File',
      'memo files': 'Memo Files',
      'memo file not exist': 'Memo file does not exist',
      'supporting documents': 'Supporting Documents',
      'user authentication': 'User Authentication',
      'delete file': 'Delete file',
      'delete file confirmation':
        'Are you sure you want to delete this document?',
      'change memo status': 'Change Memo Status',
      'change status title':
        'Are you sure you want to <0>mark memo as Completed?</0>',
      'change status not completed': 'Memo questionnaire is not yet complete.',
      'change status data loss':
        'Cached rationale loss and loss of user comments.',
      'max files':
        'You can only upload a maximum of {{maxFiles}} memo documents to a memo a time. Please consider removing one or more older document revisions currently attached to the memo to upload more',
      update: 'Update',
      version: 'Version',
      versions: 'Versions',
      'your approved memos': 'Your Approved Memos',
      changesLostTitle: 'You have unsaved changes',
      changesLostText:
        'Do you want to save your changes before leaving the page?',
      changesLostCancelButtonText: 'do not save',
      changesLostConfirmButtonText: 'save and leave',
      'your changes lost':
        'You have unsaved changes on this page. If you don’t save, all your changes will be lost.',
      'your memos': 'Your Memos',
      'recycle bin': 'Recycle Bin',
      restore: 'Restore',
      'practitioner memos': 'Practitioner Memos',
      'restore memo file': 'Restore Memo File',
      'all accounting standards': 'All Accounting Standards',
      'all statuses': 'All Statuses',
      'all clients': 'All Clients',
      'all industries': 'All Industries',
      'restore memo file confirmation message':
        'Are you sure you want to <b>restore memo {{ memoName }}?</b> <br/> This memo will be back to previous status.',
      state: 'State',
      'memo download files popup title': 'Download Policy Confirmation',
      'memo output settings': 'Memo output settings:',
      'confirm download': 'Confirm & Download',
      'memo download files button cancel': 'CANCEL',
      'memo download files button confirm': 'CONFIRM & DOWNLOAD',
      'appendix a': 'Appendix A - Decision Summary',
      'appendix b': 'Appendix B - List of Key Terms',
      'appendix c': 'Include additional info guidance in Appendix C',
      'appendix c additional': 'Appendix C - Additional Guidance',
      'additional guidance hyperlink': 'Additional Guidance Hyperlink',
      'document comments': 'Comments',
      yours: 'Yours',
      'appendixA tooltip':
        'Check this to include a summary table detailing the different questions and answers posed in this questionnaire',
      'appendixB tooltip':
        'Checking this includes a blank table that can be used as a list of key terms/glossary',
      'appendixC tooltip':
        'Checking this results in all accounting guidance being included in an appendix rather than in the body of the memo',
      'additional guidance hyperlink tooltip':
        'Checking this box will include accounting reference numbers as hyperlinks to DART. The box should not be checked if the client does not have a subscription to DART that enables them to see the referenced content',
      'comments tooltip':
        'Checking this box results in all review notes created in the Memo Logic application to appear in the downloaded output.',
      'contact support': 'CONTACT SUPPORT',
      'error page user message':
        'You are not authorized to use this system. For any technical difficulties or any <0/> other questions, please contact your Deloitte relationship manager.',
      'error page message':
        'Oops! Something went wrong. Please try again later.',
      'logout popup title': 'Logout warning',
      'logout popup message':
        'You will be logged out in {{ seconds }} seconds. Are you sure you want to log off?',
      'button text yes': 'yes',
      'button text no': 'no',
      yes: 'Yes',
      no: 'No',
      textMaxLengthIs:
        'Max number of characters for a {{ name }} field is {{ maxLength }}',
      'description more than 5000':
        'Max number of characters for a description field is 5000',
      'text more than 5000':
        'Max number of characters for a question/conclusion field is 5000',
      'node with circular dependency':
        'Node with a circular connection cannot be saved',
      memo: 'memo',
      memos: 'memos',
      day: 'day',
      days: 'days',
      'staled memos text':
        'You have {{ staled }} staled {{ memoWord }}, please consider removing. <br/> Auto archive will occur in <strong>{{ days }} {{ daysWord }}</strong>',
      'auto archive': 'Auto Archive',
      'remind me later': 'remind me later',
      'navigate to memos': 'navigate to memos',
      analysis: 'Analysis',
      purpose: 'Purpose',
      background: 'Background',
      countryAdmin: 'Country Admin',
      globalAdmin: 'Global Admin',
      memoAiAdmin: `${AI_APP_NAME} Admin`,
      'enter name': 'Enter Name',
      'draft name': 'Draft Name',
      'define the title': 'Define the {{ title }} of this decision tree.',
      'global admin user name': 'User name',
      'global admin email': 'Email',
      'global admin created date': 'Created date',
      'global admin country': 'Country',
      'global admin role': 'Role',
      'global admin activate': 'Activate',
      'global admin select role': 'Select role',
      'global admin change role': 'Change Role',
      'global admin assign role': 'Assign Role',
      'global admin popup change role':
        'Are you sure you want to change role {{ adminUserName }} from "{{ adminUserType }}" to "{{ selectedValue }}"?',
      'global admin popup assign role':
        'Are you sure you want to assign {{ adminUserName }} as "{{ selectedValue }}"?',
      'global admin toast role changed': 'Role has been successfully changed',
      country: 'Country',
      to: 'to',
      status: 'Status',
      memoName: 'Memo Name',
      'modified date & time': 'Modified date & time',
      'modified by': 'Modified By',
      clientIndustry: 'Client Industry',
      fileName: 'File name',
      requestedTimestamp: 'Requested timestamp',
      approvalTimestamp: 'Approval timestamp',
      actions: 'Actions',
      bulkAction: 'bulk action',
      exportToExcel: 'export to excel',
      onboardUser: 'onboard user',
      creator: 'Creator',
      clientName: 'Client Name',
      inactive: 'Inactive',
      recover: 'Recover',
      'expand all': 'Expand all questions',
      'collapse all': 'Collapse all questions',
      'mark as deleted title': 'Mark as Deleted',
      'mark as deleted description':
        'Are you sure you want to mark this memo as deleted?',
      notSubmitted: 'Not submitted',
      draft: 'Draft',
      archived: 'Archived',
      parent: 'Parent',
      child: 'Child',
      statement: 'Statement',
      loading: 'Loading',
      saving: 'Saving',
      'same destination': 'Several answers have the same destination.',
      'file limit':
        'There can be only a maximum of 10 documents attached to a memo at any time. Please consider removing one or more older revisions currently attached to the memo to add more.',
      accept: 'Accept',
      decline: 'Decline',
      'memo restored': 'Memo has been successfully restored.',
      'memo deleted': 'Memo has been marked as deleted.',
      footer:
        'Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited (“DTTL”), its global network of member firms, and their related entities (collectively, the “Deloitte organization”). DTTL (also referred to as “Deloitte Global”) and each of its member firms and related entities are legally separate and independent entities, which cannot obligate or bind each other in respect of third parties. DTTL and each DTTL member firm and related entity is liable only for its own acts and omissions, and not those of each other. DTTL does not provide services to clients. Please see <0>www.deloitte.com/about</0> to learn more.',
      'footer copyright':
        '© {{ currentYear }} Deloitte. All rights reserved. See <0>Privacy</0> and <1>Notices</1> for more information.',
      removeAdmin: 'Remove Admin',
      removeUser: 'Remove User',
      removeAdmins: 'Remove Admins',
      removeUsers: 'Remove Users',
      removeSelectedUsersConfirmation:
        'Are you sure you want to remove selected users?',
      removeSelectedAdminsConfirmation:
        'Are you sure you want to remove selected admins?',
      removeUserConfirmation:
        'Are you sure you want to remove <0>{{name}}</0> as a practitioner?',
      removeAdminConfirmation:
        'Are you sure you want to remove <0>{{name}}</0> as an admin?',
      noPermissionsToDeleteAdmins:
        'You are not permitted to delete a {{name}}. Admins selected to remove will remain in the User List.',
      noPermissionsToDelete: 'You are not permitted to delete a {{ name }}.',
      adminList: 'Admin List',
      userList: 'User List',
      canNotDeleteYourselfFrom: 'You can not delete yourself from {{ name }}.',
      documentation: 'Documentation',
      documentationPlaceholder: 'Enter documentation here...',
      clear: 'clear',
      clearSearch: 'Clear the search',
      reviewNotes: 'Review Notes',
      reviewNotesPlaceholder: 'Add note here...',
      openModeTitle: 'This country is set to Open Mode',
      openModeText:
        'With the Open Mode activated, all users of Audit & Assurance channel have access to all accessible trees within this Country. The Deloitte users outside the Audit & Assurance channel can have access granted by adding them to the',
      allowedList: 'Allowed List',
      restrictedMode: 'Restricted Mode',
      openMode: 'Open Mode',
      toggleModeTooltipText:
        'Open Mode provides Country Admins with the ability to open Memo Logic out to all Audit & Assurance Practitioners in their country. Restricted Mode requires that each application user must be added explicitly.',
      toggleModePopupText: 'Are you sure you want to activate {{ modeName }}?',
      onboardUsers: 'Onboard Users',
      selectUsers: 'Select Users',
      addUsers: 'add users',
      selectedUsers: 'Selected Users',
      showInMemo: 'Show in Memo',
      connectToQuestionNode: 'Connect to Question Node',
      leaveWithoutSaving: 'leave without saving',
      enterFieldHere: 'Enter {{ name }} here',
      confirmAndContinue: 'confirm & continue',
      selectMemoSections: 'Select Memo elements to be displayed in the output',
      memoOutputSettings: 'Memo Output Settings',
      summaryOfGuidance: 'Summary of Relevant Guidance',
      executiveSummary: 'Executive Summary',
      thisFieldIsRequired: 'This field is required',
      fileInfected: 'File is infected. Please upload clean file.',
      fileScanError:
        "Can't scan file with antivirus, please try your upload again",
      antivirusServiceUnavailable:
        'Antivirus service is unavailable. Please try again later',
      summaryOfGuidanceTooltip:
        'Select to include an empty section for manual entry of related data.',
      executiveSummaryTooltip:
        'Select to include accounting questions and conclusions based on questions answered in the tabular format.',
      additionalInfoTooltip:
        'Select to include all available additional information to Memo. Otherwise, Memo will display only additional information which set to be included in the Memo output.',
      conclusionTooltip:
        'Select to include a memo end point conclusion in the questionnaire.',
      questionResponsesTooltip:
        'Select to include all provided responses to Analysis section of Memo output.',
      questionResponses: 'Include Question Responses',
      uploadFiles: 'Upload Files',
      uploadFile: 'Upload File',
      dataRetention: 'Data Retention',
      addCountryGroup: 'Add Country Group',
      defaultSettings: 'Default Settings',
      inProgressThreshold:
        'Set data retention threshold for the Memos having statuses “In Progress”, “Completed”',
      labelInProgress: 'In Progress',
      nameInProgress: 'inProgressInactiveDaysCount',
      nameCompleted: 'completedInactiveDaysCount',
      labelCompleted: 'Completed',
      archivedThreshold:
        'Set data retention threshold for the Memos having statuses “Archived”, “Deleted”',
      labelArchived: 'Archived or Deleted',
      nameArchived: 'hardDeletionDaysCount',
      notificationThreshold: 'Set data retention threshold for email alerts',
      labelFirstEmail: 'First Email',
      nameFirstEmail: 'firstNotificationDaysCount',
      labelSecondEmail: 'Second Email',
      nameSecondEmail: 'secondNotificationDaysCount',
      defaultSettingsTitle: 'Default Settings by all Countries',
      dataRetentionTitle: 'Data Retention',
      disclaimerManagementTitle: 'Disclaimer Management',
      removeGroup: 'remove group',
      'No countries assigned': 'No countries assigned',
      'Click to edit': 'Click to edit',
      memoOutputDocsInfo:
        'You can choose to input {{name}} of this memo here or in the memo output document.',
      fileUploadInactiveFor: 'File Upload feature is inactive for {{country}}.',
      noChosenUsers: 'No users have\nbeen chosen yet.',
      memoNotShared: 'This memo isn’t shared\nwith anyone yet.',
      userAdded: 'This user already added',
      goToTree: 'GO TO TREE',
      improveMemoGenerationHeader: 'Improve Memo Generation Performance',
      improveMemoGenerationDescription:
        'Toggle on to improve memo generation performance',
      improveMemoGenerationWarningPopupHeader: 'Settings Update',
      improveMemoGenerationWarningPopupDescription:
        'Are you sure you want to update the settings for memo generation performance? Please consult the product team as this will impact the memo generation functionality.'
    }
  },
  fr: {
    translations: {
      selectStandard: 'Please select a standard to begin generating a new memo.'
    }
  }
};

export default resources;
