export const CREATE_TREE_SUCCESS = 'CREATE_TREE_SUCCESS';
export const CHECK_CREATE_TREE_LOADED = 'CHECK_CREATE_TREE_LOADED';
export const CHECK_CREATE_TREE_ERROR = 'CHECK_CREATE_TREE_ERROR';
export const LOAD_TREES_SUCCESS = 'LOAD_TREES_SUCCESS';
export const CHECK_TREES_LOADING = 'CHECK_TREES_LOADING';
export const LOAD_ALL_TREES_SUCCESS = 'LOAD_ALL_TREES_SUCCESS';
export const LOAD_ALL_MEMO_AI_TREES_SUCCESS = 'LOAD_ALL_MEMO_AI_TREES_SUCCESS';
export const CHECK_ALL_TREES_LOADED = 'CHECK_ALL_TREES_LOADED';
export const LOAD_USER_TREES_SUCCESS = 'LOAD_USER_TREES_SUCCESS';
export const CHECK_USER_TREES_LOADED = 'CHECK_USER_TREES_LOADED';
export const LOAD_TREE_BY_TREE_ID_SUCCESS = 'LOAD_TREE_BY_TREE_ID_SUCCESS';
export const ACTIVATE_TREE_SUCCESS = 'ACTIVATE_TREE_SUCCESS';
export const CHECK_ACTIVATE_TREE_LOADED = 'CHECK_ACTIVATE_TREE_LOADED';
export const UPDATE_TREE_SUCCESS = 'UPDATE_TREE_SUCCESS';
export const CHECK_UPDATE_TREE_LOADED = 'CHECK_UPDATE_TREE_LOADED';
export const SELECT_TREE = 'SELECT_TREE';
export const DELETE_TREES_SUCCESS = 'DELETE_TREES_SUCCESS';
export const CHECK_DELETE_TREES = 'CHECK_DELETE_TREES';
export const SET_TREE_LIST_LOADED = 'SET_TREE_LIST_LOADED';
export const GET_USER_TREES_PENDING = 'GET_USER_TREES_PENDING';
export const GET_USER_TREES_FULFILLED = 'GET_USER_TREES_FULFILLED';
export const GET_USER_TREES_REJECTED = 'GET_USER_TREES_REJECTED';

export type TreeState = Readonly<{
  currentUserTreeList: DTO.Tree[];
  isCurrentUserTreeListLoading: boolean;
  isCurrentUserTreeListLoaded: boolean;
  treeList: DTO.Tree[];
  isTreeListLoaded: boolean;
  isTreeListLoading: boolean;
  userTreeList: DTO.Tree[];
  isUserTreeListLoaded: boolean;
  treeInstance: DTO.Tree | null;
  createdTreeInstance: DTO.Tree | null;
  isCreatedTreeInstanceLoading: boolean;
  isActivateTreeLoading: boolean;
  isUpdateTreeLoading: boolean;
  isTreesDeleteLoading: boolean;
  userTreesLoading: boolean;
  userTrees: DTO.Tree[];
}>;

export type TreeActionTypes = any;
