import React, { FC } from 'react';

import { CommonIconPropsI } from './common-icon-props.type';

export const DocumentIcon: FC<CommonIconPropsI> = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20.4167 3.5C20.703 3.5 20.9412 3.70635 20.9906 3.97848L21 4.08333V9.5935L24.2958 11.6532C25.0877 12.1482 25.591 12.9895 25.6588 13.9123L25.6667 14.1266V20.4167C25.6667 20.703 25.4603 20.9412 25.1882 20.9906L25.0833 21H21.4764C20.7598 21 20.0575 20.8115 19.4392 20.4555L19.2114 20.3142L16.0432 18.2018L15.9676 18.1481C15.1003 17.4976 14.9246 16.2673 15.575 15.4C16.2141 14.5479 17.3767 14.3075 18.2944 14.8104L18.4536 14.9069L19.8333 15.8258V4.66667H8.16667V21H15.75C16.0364 21 16.2745 21.2064 16.3239 21.4785L16.3333 21.5833C16.3333 21.8697 16.127 22.1079 15.8549 22.1573L15.75 22.1667H7.58333C7.29696 22.1667 7.05879 21.9603 7.0094 21.6882L7 21.5833V4.08333C7 3.79696 7.20635 3.55879 7.47848 3.5094L7.58333 3.5H20.4167ZM21 10.9678V16.9167C21 17.316 20.6185 17.5772 20.2654 17.481L20.1779 17.4493L20.0931 17.402L17.8065 15.8776C17.3833 15.5955 16.8135 15.6931 16.5083 16.1C16.2709 16.4166 16.3049 16.8525 16.561 17.1219L16.6545 17.2052L16.7036 17.2402L19.8585 19.3435C20.2692 19.6173 20.7414 19.7816 21.2306 19.823L21.4764 19.8333H24.5V14.1266C24.5 13.6439 24.3011 13.1884 23.9595 12.8618L23.8249 12.7456L23.6775 12.6426L21 10.9678ZM16.9167 11.6667C17.2388 11.6667 17.5 11.9278 17.5 12.25C17.5 12.5364 17.2936 12.7745 17.0215 12.8239L16.9167 12.8333H11.0833C10.7612 12.8333 10.5 12.5722 10.5 12.25C10.5 11.9636 10.7064 11.7255 10.9785 11.6761L11.0833 11.6667H16.9167ZM16.9167 9.33333C17.2388 9.33333 17.5 9.5945 17.5 9.91667C17.5 10.203 17.2936 10.4412 17.0215 10.4906L16.9167 10.5H11.0833C10.7612 10.5 10.5 10.2388 10.5 9.91667C10.5 9.6303 10.7064 9.39212 10.9785 9.34273L11.0833 9.33333H16.9167ZM16.9167 7C17.2388 7 17.5 7.26117 17.5 7.58333C17.5 7.8697 17.2936 8.10788 17.0215 8.15727L16.9167 8.16667H11.0833C10.7612 8.16667 10.5 7.9055 10.5 7.58333C10.5 7.29696 10.7064 7.05879 10.9785 7.0094L11.0833 7H16.9167Z'
        fill='black'
      />
    </svg>
  );
};
