import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { signalrService } from 'services/signalr.service';
import { USER_PATH } from 'shared/constants/routesConstants';
import { toastScanFailed } from 'shared/utils/toastUtils';
import { setActiveFileListMemoData } from 'store/memo/memo.actions';
import { checkUserRole } from '../utils/commonUtils';

export const useGlobalMemoFileScanListener = (
  currentUser: DTO.CurrentUser | null
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAiAdmin = checkUserRole(currentUser?.user_type).aiAdmin;

  useEffect(() => {
    if (!isAiAdmin && currentUser?.user_id && currentUser?.is_TOU_accepted) {
      const connection = signalrService.getConnection({
        url: `fileScan`
      });
      // INFO: Just for toaster testing
      /* toastScanFailed('serviceUnavailable', () => {
        dispatch(
          setActiveFileListMemoData({
            memo_id: 4,
            memo_version_id: 4,
            memo_name: 'test0809',
            is_completed: true,
            active_memo_status: 'InProgress',
            file_type: 'attachment'
          })
        );
        if (history.location.pathname !== USER_PATH.MEMO) {
          history.push(USER_PATH.MEMO);
        }
      }); */

      const start = async () => {
        try {
          await connection.start();
          connection.on(
            'GetNotificationMessage',
            (msg: DTO.WSSomeMemoFilesScan) => {
              toastScanFailed(msg.scan_status, () => {
                dispatch(setActiveFileListMemoData(msg));
                if (history.location.pathname !== USER_PATH.MEMO) {
                  history.push(USER_PATH.MEMO);
                }
              });
            }
          );
          await connection.invoke('Subscribe').catch(() => {});
        } catch (err) {
          console.error('Connection failed: ', err);
        }
      };
      start();

      return () => {
        const stop = async () => {
          try {
            await connection.stop();
          } catch (err) {
            console.error('Disconnection failed: ', err);
          }
        };
        stop();
      };
    }

    return () => {};
  }, [
    currentUser?.user_id,
    dispatch,
    history,
    currentUser?.is_TOU_accepted,
    isAiAdmin
  ]);
};
