export const selectIsTokenLoaded = ({ user: { isTokenLoaded } }) =>
  isTokenLoaded;
export const selectErrorStatus = ({ user }: any): any => user.errorStatus;
export const selectCurrentUser = ({ user: { currentUser } }): DTO.CurrentUser =>
  currentUser;
export const selectCurrentUserCountry = ({
  user: { currentUser }
}): DTO.CurrentUser['country'] => currentUser?.country || '';
export const selectIsUserLoading = ({ user: { isLoading } }): boolean =>
  isLoading;
export const selectIsUserLoaded = ({
  user: { isCurrentUserLoaded }
}): boolean => isCurrentUserLoaded;
export const selectUserDataList = ({
  user: { userDataList }
}): DTO.UserData[] | DTO.AiTreesManagementCreators[] => userDataList;
export const selectApprovalUsersData = ({
  user: { usersData }
}): DTO.UserData[] => usersData;
export const selectIsApprovalUsersLoaded = ({
  user: { isUsersDataLoaded }
}): boolean => isUsersDataLoaded;
export const selectIsUserDataListLoading = ({
  user: { isUserDataListLoading }
}): boolean => isUserDataListLoading;
export const selectIsUserDataListLoaded = ({
  user: { isUserDataListLoaded }
}): boolean => isUserDataListLoaded;
export const selectAdminDataList = ({
  user: { adminDataList }
}): DTO.AdminData[] => adminDataList;
export const selectIsAdminDataListLoading = ({
  user: { isAdminDataListLoading }
}): boolean => isAdminDataListLoading;
export const selectIsAdminDataListLoaded = ({
  user: { isAdminDataListLoaded }
}): boolean => isAdminDataListLoaded;
export const selectIsUpdateAdminLoading = ({
  user: { isUpdateAdminLoading }
}): boolean => isUpdateAdminLoading;
export const selectAdminSearchValue = ({
  user: { adminSearchValue }
}): string => adminSearchValue;
export const selectUserSettingsMemoAppearance = ({
  user: { settings }
}): DTO.MemoSectionAppearanceData => settings.memoAppearance;
export const selectUserSettingsMemoAppearanceLoading = ({
  user: { settings }
}): boolean => settings.memoAppearanceLoading;
