import { AxiosPromise } from 'axios';

import { axiosService } from '../axios.service';

class treeVersionApi {
  static getAllTreeVersions() {
    return axiosService
      .instance({
        url: '/tree/version/'
      })
      .then(({ data }) => data);
  }

  static getTreeVersionByTreeVersionId(treeVersionId) {
    return axiosService
      .instance({
        url: `/tree/version/${treeVersionId}/`
      })
      .then(({ data }) => data);
  }

  static getActiveTreeVersionByTreeId(treeId) {
    return axiosService
      .instance({
        url: `/tree/version/?tree=${treeId}&isActive=true`
      })
      .then((response) => {
        const { data } = response;
        return data.length > 0 ? data[0] : data;
      });
  }

  static postTree(tree) {
    return axiosService
      .instance({
        method: 'put',
        url: 'tree/',
        data: tree
      })
      .then((response) => {
        const { data } = response;
        return data;
      });
  }

  static postNewVersion(treeId, file) {
    const formData = new FormData();
    formData.append('file_data', file);

    return axiosService
      .instance({
        method: 'post',
        url: `tree/${treeId}/version`,
        data: formData
      })
      .then((response) => {
        const { data } = response;
        return data;
      });
  }

  static activateTreeVersionByTreeVersionId({ treeVersionId, countries }) {
    return axiosService
      .instance({
        method: 'post',
        url: `tree/version/${treeVersionId}/activate`,
        data: countries
      })
      .then((response) => response);
  }

  static activateAITreeVersionByTreeVersionId({ treeVersionId, isActive }) {
    return axiosService
      .instance({
        method: 'post',
        url: `ai/admin/tree/version/${treeVersionId}/activate`,
        data: isActive
      })
      .then((response) => response);
  }

  static getTreeVersionsByTreeId(treeId): Promise<DTO.TreeVersionInstance[]> {
    return axiosService
      .instance({
        url: `tree/${treeId}/version`
      })
      .then(({ data }) => data);
  }

  static getCountries() {
    return axiosService
      .instance({
        url: `/countries`
      })
      .then(({ data }) => data);
  }

  static getTreeVersionDraft(
    treeVersionDraftId: number
  ): AxiosPromise<DTO.TreeVersionDraft> {
    return axiosService.instance({
      url: `/tree/version/draft/${treeVersionDraftId}`
    });
  }

  static postTreeVersionDraft(data: DTO.PostTreeVersionDraftBody) {
    return axiosService.instance({
      url: '/tree/version/draft',
      method: 'post',
      data
    });
  }

  static deleteTreeVersionDraft(treeVersionDraftId: number) {
    return axiosService.instance({
      url: `/tree/version/draft/${treeVersionDraftId}`,
      method: 'delete'
    });
  }
}

export default treeVersionApi;
