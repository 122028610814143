import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { NodeType } from 'shared/constants/treeConstants';
import { MenuItem } from 'shared/utils/commonUtils';
import {
  AI_APP_NAME,
  TABLE_COLUMN_WIDTH,
  TABLE_TYPES
} from 'shared/constants/commonConstants';
import { SplitIcon } from 'icons/SplitIcon';
import { DocumentIcon } from 'icons/DocumentIcon';
import { USER_PATH } from 'shared/constants/routesConstants';
import { checkSystemHas12hTimeFormat } from 'shared/utils/check-system-has-12h-time-format';
import GearIcon from '../icons/GearIcon';

const getNavigationOptions = (
  userPaths: (USER_PATH.MEMO | USER_PATH.TREE | USER_PATH.ADMIN)[],
  isAiAdmin = false
) => {
  const navOptions: Record<
    USER_PATH.MEMO | USER_PATH.TREE | USER_PATH.ADMIN,
    MenuItem
  > = {
    [USER_PATH.MEMO]: {
      key: 'memo',
      name: 'Memos',
      url: USER_PATH.MEMO,
      icon: <DocumentIcon />
    },
    [USER_PATH.TREE]: {
      key: 'tree',
      name: isAiAdmin ? `${AI_APP_NAME} Tree Management` : 'Tree Management',
      url: USER_PATH.TREE,
      icon: <SplitIcon />
    },
    [USER_PATH.ADMIN]: {
      key: 'admin',
      name: 'Administration',
      url: USER_PATH.ADMIN,
      icon: <GearIcon />
    }
  };

  return userPaths.map((path) => navOptions[path]);
};

class dataHandler {
  static getMenuItems = (type: DTO.UserType): MenuItem[] => {
    const menuItems: Record<DTO.UserType, MenuItem[]> = {
      admin: getNavigationOptions([USER_PATH.MEMO, USER_PATH.ADMIN]),
      globalAdmin: getNavigationOptions([
        USER_PATH.MEMO,
        USER_PATH.TREE,
        USER_PATH.ADMIN
      ]),
      user: getNavigationOptions([USER_PATH.MEMO]),
      aiAdmin: getNavigationOptions([USER_PATH.TREE, USER_PATH.ADMIN], true)
    };

    return menuItems[_.trim(type)];
  };

  static getTooltipSize = (
    tableType: MEMO.TableType,
    id: string
  ): string | null => {
    switch (tableType) {
      case TABLE_TYPES.MEMO: {
        if (id === 'memo_client_industry') {
          return 'tooltip-sm';
        }
        return 'tooltip-md';
      }
      case TABLE_TYPES.SIMILAR_MEMO: {
        if (id === 'memo_client_industry') {
          return 'tooltip-md';
        }
        return 'tooltip-lg';
      }
      default:
        return null;
    }
  };

  static getColumnWidth = (type: MEMO.TableType, id: string) => {
    const classes = {
      [TABLE_TYPES.MEMO]: {
        memo_name: TABLE_COLUMN_WIDTH['10%'],
        memo_created_timestamp: TABLE_COLUMN_WIDTH['15%'],
        memo_creator: TABLE_COLUMN_WIDTH['10%'],
        memo_client_industry: TABLE_COLUMN_WIDTH['20%'],
        treePath: TABLE_COLUMN_WIDTH['10%'],
        accounting_standard_name: TABLE_COLUMN_WIDTH['15%'],
        memo_client_name: TABLE_COLUMN_WIDTH['15%']
      },
      [TABLE_TYPES.SIMILAR_MEMO]: {
        memo_name: TABLE_COLUMN_WIDTH['15%'],
        memo_created_timestamp: TABLE_COLUMN_WIDTH['20%'],
        memo_creator: TABLE_COLUMN_WIDTH['10%'],
        memo_client_industry: TABLE_COLUMN_WIDTH['20%'],
        accounting_standard_name: TABLE_COLUMN_WIDTH['20%']
      },
      [TABLE_TYPES.TREE]: {
        tree_name: TABLE_COLUMN_WIDTH['20%'],
        tree_description: TABLE_COLUMN_WIDTH['25%'],
        tree_created_timestamp: TABLE_COLUMN_WIDTH['15%'],
        tree_is_active: TABLE_COLUMN_WIDTH['10%'],
        tree_creator_user_id: TABLE_COLUMN_WIDTH['15%'],
        editTree: TABLE_COLUMN_WIDTH['5%'],
        selectTree: TABLE_COLUMN_WIDTH['10%']
      },
      [TABLE_TYPES.ACCOUNTING_STANDARD_ADMINISTRATION]: {
        tree_name: TABLE_COLUMN_WIDTH['30%'],
        tree_description: TABLE_COLUMN_WIDTH['50%'],
        tree_is_active: TABLE_COLUMN_WIDTH['20%']
      },
      [TABLE_TYPES.USER_ADMINISTRATION]: {
        user_name: TABLE_COLUMN_WIDTH['20%'],
        email: TABLE_COLUMN_WIDTH['30%'],
        created_date: TABLE_COLUMN_WIDTH['20%'],
        accessible_trees: TABLE_COLUMN_WIDTH['20%']
      }
    };
    return type ? classes[type][id] : '';
  };

  /**
   *
   * @param {string} id Name of column from db
   */
  static formatTableColumn(id) {
    if (id === 'memo_client_name') return _.upperCase('Client Name');
    return _.upperCase(id);
  }

  /**
   *
   * @param {any[]} tableData
   * @param {string[]} hiddenList Columns that will not be shown
   * @param {any[]} customsColumns
   * @param {string} tableType
   */
  static generateTableColumns = (
    tableData,
    hiddenList,
    customsColumns,
    tableType?,
    hideNoResults?: boolean
  ) => {
    const columnsWithFilter: any[] = [
      // {
      //   name: 'memo_client_name',
      //   filter: textFilter
      // }
    ];

    const columns: any = [];
    if (!_.isEmpty(tableData)) {
      tableData.forEach((data, index) => {
        const findColumnFilter = (id) => {
          const columnFilter = columnsWithFilter.find((col) => col.name === id);
          return columnFilter ? columnFilter.filter() : undefined;
        };
        if (index === 0) {
          _.forOwn(data, (_value, id) => {
            if (id.indexOf('set') === -1) {
              const column = {
                dataField: id,
                text: dataHandler.formatTableColumn(id),
                sort: true,
                filter: findColumnFilter(id),
                classes: _.includes(hiddenList, id)
                  ? ''
                  : dataHandler.getColumnWidth(tableType, id),
                hidden: _.includes(hiddenList, id),
                formatter: (cell) => {
                  if (cell === true) {
                    return 'Yes';
                  }
                  if (cell === false) {
                    return 'No';
                  }
                  if (_.includes(id, 'time')) {
                    const date = moment(cell);
                    return date.isValid()
                      ? moment(cell).format(
                          `YYYY-MM-DD ${
                            checkSystemHas12hTimeFormat() ? 'hh:mm A' : 'HH:mm'
                          }`
                        )
                      : '';
                  }
                  if (_.includes(cell, 'http')) {
                    const urlArray = cell.split('/');
                    return urlArray[urlArray.length - 2];
                  }
                  if (
                    _.includes(cell, '@') &&
                    (id.toUpperCase().includes('EMAIL') ||
                      id.toUpperCase().includes('CREATOR'))
                  ) {
                    const emailArray = cell.split('@');
                    return emailArray[0];
                  }
                  return cell && cell.length > 25 ? (
                    <div className='long-value-wrap'>
                      <div className='long-value-input'>
                        <p className='cursor-pointer'>
                          {cell.substring(0, 25)}...
                        </p>
                      </div>
                      <div
                        className={`tooltip ${dataHandler.getTooltipSize(
                          tableType,
                          id
                        )}`}
                      >
                        {cell}
                      </div>
                    </div>
                  ) : (
                    <div>{cell}</div>
                  );
                }
              };
              columns.push(column);
            }
          });

          customsColumns.forEach((customColumn) => {
            columns.push(customColumn);
          });
        }
      });
    }

    if (_.isEmpty(columns)) {
      if (hideNoResults) {
        columns.push({
          dataField: '',
          text: ''
        });
        return columns;
      }
      columns.push({
        dataField: '',
        text: 'No results found.'
      });
    }
    return columns;
  };

  static addAdditionalInfo = (additionalInfo) => {
    let attributes = {};
    if (additionalInfo) {
      additionalInfo.forEach((elem) => {
        attributes = { ...attributes, ...elem };
      });
    }
    return attributes;
  };

  static convertTreeDataToD3GraphData = (
    treeData: DTO.TreeVersionInstance
  ): MEMO.GraphData => {
    const output: MEMO.GraphData = { nodes: [], links: [] };

    _.forOwn(treeData.node_set, (nodeValue, nodeKey) => {
      const connectionSet = {};
      _.entries(nodeValue.outgoing_connection_set).forEach(
        ([answer, target]) => {
          connectionSet[answer] = target.toString();
        }
      );

      output.nodes.push({
        node_number: nodeValue.node_number.toString(),
        id: nodeKey.toString(),
        label: nodeKey,
        node_text: nodeValue.node_text || '',
        node_html_text:
          nodeValue.node_html_text || `<p>${nodeValue.node_text || ''}</p>`,
        node_type: nodeValue.node_type,
        node_description: nodeValue.node_description || '',
        outgoing_connection_set: connectionSet,
        additional_info_set: nodeValue.additional_info_set,
        class: `${nodeValue.node_type}-node`,
        is_rationale: !!nodeValue.is_rationale,
        show_node: !!nodeValue.show_node,
        is_conclusion: !!nodeValue.is_conclusion,
        accounting_question_node_number: `${
          nodeValue.accounting_question_node_number || 0
        }`
      });

      if (
        nodeValue.outgoing_connection_set &&
        !_.isEmpty(nodeValue.outgoing_connection_set)
      ) {
        _.forOwn(
          nodeValue.outgoing_connection_set,
          (valueConnection, keyConnection) => {
            const isParentToChildConnection =
              nodeValue.node_type === NodeType.Parent &&
              _.entries(treeData.node_set).find(([id, item]) => {
                return (
                  item.node_type === NodeType.Child &&
                  id === valueConnection.toString()
                );
              });

            const label =
              nodeValue.node_type === NodeType.Purpose ||
              nodeValue.node_type === NodeType.Background ||
              isParentToChildConnection
                ? ''
                : keyConnection;

            output.links.push({
              source: nodeKey,
              target: valueConnection.toString(),
              label,
              answer: keyConnection
            });
          }
        );
      }
    });

    return output;
  };

  static convertD3GraphDataToTreeData = (
    d3GraphData: MEMO.GraphData,
    lastVersion: number,
    treeId: string
  ) => {
    const outputData = {
      node_set: {},
      tree_id: treeId,
      tree_version_number: 0
    };

    d3GraphData.nodes.forEach((node) => {
      outputData.node_set[node.node_number] = node;

      d3GraphData.links.forEach((link) => {
        if (link && link.source === node.node_number) {
          if (
            node.node_type === NodeType.Purpose ||
            node.node_type === NodeType.Background
          ) {
            outputData.node_set[node.node_number].outgoing_connection_set = {
              ...{ [link.label || node.node_number]: link.target }
            };
          } else {
            outputData.node_set[node.node_number].outgoing_connection_set = {
              ...outputData.node_set[node.node_number].outgoing_connection_set,
              ...{ [link.answer]: link.target }
            };
          }
        }
      });
    });

    outputData.tree_version_number = lastVersion + 1;
    return outputData;
  };
}

export default dataHandler;
