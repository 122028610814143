import React, { lazy, Suspense, FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RouteProps,
  Route,
  Switch,
  useHistory,
  Redirect
} from 'react-router-dom';

import LoadOneTrustCookieScript from './services/loadOneTrustCookie.service';
import { PrivateRoute } from './components/common/PrivateRoute';
import { USER_TYPES } from './shared/constants/commonConstants';
import { USER_PATH } from './shared/constants/routesConstants';
import {
  selectRedirection,
  selectRedirectLink
} from './store/global/global.selectors';
import { disableRedirection } from './store/global/global.actions';
import { Loading } from './components/common/Loading';
import { selectCurrentUser } from './store/selectors/userSelectors';
import { checkUserRole } from './shared/utils/commonUtils';

const TermsPage = lazy(() => import('./components/terms-page/TermsPage'));
const RootRedirect = lazy(() => import('./components/root-redirect'));
const CreateMemoContainer = lazy(
  () => import('./containers/CreateMemoContainer/CreateMemoContainer')
);
const MemoContainer = lazy(() => import('./containers/MemoContainer'));
const AdminContainer = lazy(
  () => import('./containers/AdminContainer/AdminContainer')
);
const TreeContainer = lazy(
  () => import('./containers/TreeContainer/TreeContainer')
);
const AiTreeContainer = lazy(
  () => import('./containers/AiTreeContainer/AiTreeContainer')
);
const ErrorContainer = lazy(
  () => import('./containers/ErrorContainer/ErrorContainer')
);

export const Routes: FC<RouteProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);
  const redirect = useSelector(selectRedirection);
  const redirectLink = useSelector(selectRedirectLink);
  const isAiAdmin = checkUserRole(currentUser.user_type).aiAdmin;

  useEffect(() => {
    if (redirect && redirectLink) {
      dispatch(disableRedirection);
      history.push(redirectLink);
    }
  }, [dispatch, history, redirect, redirectLink]);

  useEffect(() => {
    LoadOneTrustCookieScript.loadScript();
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route
          path={[USER_PATH.ROOT, USER_PATH.AUTH]}
          exact={true}
          component={RootRedirect}
        />

        <PrivateRoute
          exact={true}
          accessRoles={[
            USER_TYPES.USER,
            USER_TYPES.ADMIN,
            USER_TYPES.GLOBAL_ADMIN
          ]}
          path={USER_PATH.MEMO}
          render={useCallback(
            () => (
              <MemoContainer />
            ),
            []
          )}
        />

        <PrivateRoute
          exact={true}
          accessRoles={[
            USER_TYPES.USER,
            USER_TYPES.ADMIN,
            USER_TYPES.GLOBAL_ADMIN
          ]}
          path={USER_PATH.EDIT_MEMO}
          component={CreateMemoContainer}
        />

        <PrivateRoute
          exact={true}
          accessRoles={[USER_TYPES.GLOBAL_ADMIN, USER_TYPES.MEMO_AI_ADMIN]}
          path={USER_PATH.TREE}
          render={useCallback(
            () => (isAiAdmin ? <AiTreeContainer /> : <TreeContainer />),
            [isAiAdmin]
          )}
        />

        <PrivateRoute
          exact={true}
          accessRoles={[
            USER_TYPES.ADMIN,
            USER_TYPES.GLOBAL_ADMIN,
            USER_TYPES.MEMO_AI_ADMIN
          ]}
          path={USER_PATH.ADMIN}
          render={useCallback(
            () => (
              <AdminContainer />
            ),
            []
          )}
        />

        <Route
          exact={true}
          path={USER_PATH.ERROR}
          render={useCallback(
            () => (
              <ErrorContainer />
            ),
            []
          )}
        />

        <Route
          exact={true}
          path={USER_PATH.TERMS}
          render={useCallback(
            () => (
              <TermsPage />
            ),
            []
          )}
        />

        <Redirect from={USER_PATH.WILDCARD} to={USER_PATH.ROOT} />
      </Switch>
    </Suspense>
  );
};
