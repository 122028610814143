export const LOAD_ALL_TREE_VERSIONS_SUCCESS = 'LOAD_ALL_TREE_VERSIONS_SUCCESS';
export const LOAD_TREE_VERSIONS_BY_TREE_ID_SUCCESS =
  'LOAD_TREE_VERSIONS_BY_TREE_ID_SUCCESS';

export const LOAD_TREE_VERSIONS_BY_TREE_ID_LOADING =
  'LOAD_TREE_VERSIONS_BY_TREE_ID_LOADING';
export const LOAD_TREE_VERSION_BY_TREE_VERSION_ID_SUCCESS =
  'LOAD_TREE_VERSION_BY_TREE_VERSION_ID_SUCCESS';
export const CHECK_TREE_VERSION_BY_TREE_VERSION_ID_LOADED =
  'CHECK_TREE_VERSION_BY_TREE_VERSION_ID_LOADED';
export const CHECK_CREATE_TREE_VERSION_ERROR =
  'CHECK_CREATE_TREE_VERSION_ERROR';
export const CREATE_TREE_VERSION_SUCCESS = 'CREATE_TREE_VERSION_SUCCESS';
export const CHECK_CREATE_TREE_VERSION_LOADED =
  'CHECK_CREATE_TREE_VERSION_LOADED';
export const LOAD_ACTIVE_TREE_VERSION_SUCCESS =
  'LOAD_ACTIVE_TREE_VERSION_SUCCESS';
export const ACTIVATE_VERSION_SUCCESS = 'ACTIVATE_VERSION_SUCCESS';
export const LOAD_ACTIVATE_VERSION_LOADING = 'LOAD_ACTIVATE_VERSION_LOADING';
export const LOAD_COUNTRIES_SUCCESS = 'LOAD_COUNTRIES_SUCCESS';
export const LOAD_COUNTRIES_PENDING = 'LOAD_COUNTRIES_PENDING';

export const POST_TREE_VERSION_DRAFT_PENDING =
  'POST_TREE_VERSION_DRAFT_PENDING';
export const POST_TREE_VERSION_DRAFT_FULFILLED =
  'POST_TREE_VERSION_DRAFT_FULFILLED';
export const POST_TREE_VERSION_DRAFT_REJECTED =
  'POST_TREE_VERSION_DRAFT_REJECTED';

export const DELETE_TREE_VERSION_DRAFT_PENDING =
  'DELETE_TREE_VERSION_DRAFT_PENDING';
export const DELETE_TREE_VERSION_DRAFT_FULFILLED =
  'DELETE_TREE_VERSION_DRAFT_FULFILLED';
export const DELETE_TREE_VERSION_DRAFT_REJECTED =
  'DELETE_TREE_VERSION_DRAFT_REJECTED';

export const GET_TREE_VERSION_DRAFT_PENDING = 'GET_TREE_VERSION_DRAFT_PENDING';
export const GET_TREE_VERSION_DRAFT_FULFILLED =
  'GET_TREE_VERSION_DRAFT_FULFILLED';
export const GET_TREE_VERSION_DRAFT_REJECTED =
  'GET_TREE_VERSION_DRAFT_REJECTED';
export const SET_TREE_VERSION_STATUS = 'SET_TREE_VERSION_STATUS';

export type TreeVersionState = Readonly<{
  treeVersionList: DTO.TreeVersionInstance[];
  isTreeVersionListByTreeIdLoaded: boolean;
  isTreeVersionListByTreeIdLoading: boolean;
  treeVersionListByTreeId: DTO.TreeVersionInstance[];
  treeVersionInstance: DTO.TreeVersionInstance;
  isTreeInstanceLoading: boolean;
  isActivateVersionLoading: boolean;
  isSavedTreeVersionLoading: boolean;
  savedTreeVersion: DTO.TreeVersionInstance | Record<string, unknown>;
  countries: DTO.Countries;
  isCountriesLoaded: boolean;
  isCountriesLoading: boolean;
  treeVersionDraft: DTO.TreeVersionDraft | null;
  treeVersionDraftLoading: boolean;
}>;

export type TreeVersionActionTypes = any;
