import axios, { AxiosRequestConfig } from 'axios';

import { getWindow } from '../shared/utils/commonUtils';

class AxiosService {
  instance = axios.create({
    baseURL: getWindow().APP_CONFIG.API_URL,
    headers: { 'content-type': 'application/json' }
  });

  setNewDefaults = (token: string, countryCode?: string) => {
    this.instance.defaults.baseURL = countryCode
      ? getWindow().APP_CONFIG.COUNTRY_URLS[countryCode].API
      : this.instance.defaults.baseURL;
    this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  };
}

export const axiosService = new AxiosService();

// TODO: delete this after demo or after 21.12.2023

axiosService.instance.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    if (request.url && request.baseURL) {
      if (request.url.includes('ai/')) {
        // eslint-disable-next-line compat/compat
        request.baseURL = getWindow().APP_CONFIG.COUNTRY_URLS.US.API;
      }
    }

    return request;
  }
);
