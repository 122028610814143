import { HubConnectionBuilder } from '@microsoft/signalr';

import { getWindow, getCookie } from '../shared/utils/commonUtils';
import { getFromSessionStorage } from '../shared/utils/storageUtils';
import {
  COUNTRY_COOKIE_KEY,
  TOKEN_KEY
} from '../shared/constants/commonConstants';

class SignalrService {
  getConnection = ({ url = '', isNeedOnlyAmeEnv = false }) => {
    const userCountry = getCookie(COUNTRY_COOKIE_KEY);
    // TODO: revert changes of this after demo or after 21.12.2023
    let hubsUrl;

    if (isNeedOnlyAmeEnv) {
      hubsUrl = getWindow().APP_CONFIG.COUNTRY_URLS.US.HUBS;
    } else {
      hubsUrl = userCountry
        ? getWindow().APP_CONFIG.COUNTRY_URLS[userCountry].HUBS
        : getWindow().APP_CONFIG.HUBS_URL;
    }

    return new HubConnectionBuilder()
      .withUrl(`${hubsUrl}${url}`, {
        accessTokenFactory: () =>
          getFromSessionStorage<DTO.AuthTokenValue>(TOKEN_KEY)?.value ?? ''
      })
      .withAutomaticReconnect()
      .build();
  };
}

export const signalrService = new SignalrService();
