import { Reducer } from 'redux';

import {
  DEFAULT_SETTINGS_LOADING,
  LOAD_DEFAULT_SETTINGS_SUCCESS,
  SettingsState,
  UPDATE_DEFAULT_SETTINGS_SUCCESS
} from './settings.types';

const initialState: SettingsState = {
  loading: false,
  settings: {} as DTO.AdminSettings
};

const settingsReducer: Reducer<SettingsState> = (
  state = initialState,
  action
): SettingsState => {
  switch (action.type) {
    case DEFAULT_SETTINGS_LOADING:
      return {
        ...state,
        loading: true
      };

    case LOAD_DEFAULT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload
      };

    case UPDATE_DEFAULT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload
      };

    default:
      return state;
  }
};

export default settingsReducer;
