import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import InfoToastIcon from '../../../icons/InfoToastIcon';
import { ShieldIcon } from '../../../icons/ShieldIcon';
import ThemedButton from '../ThemedButton/ThemedButton';

import './ToastTreeGenerationStatus.scss';

interface Props {
  messageData: DTO.WSTreeVersionGeneration;
  onApply: VoidFunction;
}

const getContent = (
  status: DTO.WSTreeVersionGeneration['task_status']
): {
  IconComponent: FC;
  isError: boolean;
} => {
  switch (status) {
    case 'completed': {
      return {
        IconComponent: InfoToastIcon,
        isError: false
      };
    }
    default: {
      return {
        IconComponent: ShieldIcon,
        isError: true
      };
    }
  }
};

const ToastTreeGenerationStatus: FC<Props> = ({ messageData, onApply }) => {
  const { task_status: status, title, message } = messageData;
  const { t } = useTranslation();

  const { IconComponent, isError } = getContent(status);

  return (
    <div className='toastTreeGenerationStatus_toast'>
      <div className='toastTreeGenerationStatus_toast__content'>
        <div className='toastTreeGenerationStatus_toast__icon'>
          <IconComponent />
        </div>
        <span className='toastTreeGenerationStatus_toast__title'>
          {t(title)}
        </span>
        <span className='toastTreeGenerationStatus_toast__message'>
          {t(message)}
        </span>
      </div>
      <div className='toastTreeGenerationStatus_toast__actions'>
        <ThemedButton variant='outline-grey' size='small'>
          {t('cancel')}
        </ThemedButton>
        <ThemedButton
          variant={isError ? 'solid-red' : 'solid-blue'}
          size='small'
          onClick={onApply}
        >
          {t('goToTree')}
        </ThemedButton>
      </div>
    </div>
  );
};

export default ToastTreeGenerationStatus;
