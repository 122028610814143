import { AxiosPromise } from 'axios';

import { axiosService } from '../axios.service';

class userAiApi {
  static getAdminData() {
    return axiosService
      .instance({
        url: `ai/admin/users`
      })
      .then(({ data }) => data);
  }

  static getOPMAdminData(keyword: string) {
    return axiosService
      .instance({
        url: `ai/admin/users/search`,
        params: { keyword }
      })
      .then(({ data }) => data);
  }

  static getAiUsers() {
    return axiosService
      .instance({
        url: 'ai/admin/trees/management/creators'
      })
      .then(({ data }) => data);
  }

  static updateAdmin(email: string) {
    return axiosService
      .instance({
        method: 'post',
        url: `ai/admin/users/add`,
        data: {
          email
        }
      })
      .then(({ data }) => data);
  }

  static removeAdmin(userId: DTO.CommonUserType['user_id']) {
    return axiosService
      .instance({
        method: 'delete',
        url: `ai/admin/user/${userId}`
      })
      .then(({ data }) => data);
  }

  static exportAdmins(): AxiosPromise<Blob> {
    return axiosService.instance({
      url: 'ai/admin/users/report',
      responseType: 'blob'
    });
  }
}

export default userAiApi;
