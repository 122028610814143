import _ from 'lodash';

import {
  SET_IS_LOADED_TOKEN,
  LOAD_USERS_SUCCESS,
  LOAD_AUTHENTICATE_LOADING,
  LOAD_AUTHENTICATE_SUCCESS,
  LOAD_AUTHENTICATE_FAIL,
  LOAD_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_ADMIN_SUCCESS,
  CHECK_APPROVAL_USERS_LOADING,
  LOAD_APPROVAL_USERS_SUCCESS,
  CHECK_USERS_LOADING,
  LOAD_ADMIN_DATA_SUCCESS,
  CHECK_ADMIN_DATA_LOADED,
  CHECK_UPDATE_ADMIN_LOADED,
  UPDATE_ADMIN_SUCCESS,
  SET_ADMIN_SEARCH_VALUE,
  SET_LAST_ADMIN_SEARCH_REQUEST_TIME,
  SET_USER_SETTINGS,
  SET_USER_SETTINGS_LOADING,
  ONBOARD_USERS_SUCCESS
} from './actionTypes';
import { userApi } from '../../services';
import { PREVENT_LOAD_DASHBOARD } from '../../shared/constants/commonConstants';
import { checkUserRole } from '../../shared/utils/commonUtils';
import { getEnvironmentConfig, handleErrors } from '../global/global.actions';
import { setToLocalStorage } from '../../shared/utils/storageUtils';
import { toastSuccess } from '../../shared/utils/toastUtils';
import resources from '../../resources';
import userAiApi from '../../services/api/userAiApi';

export function setIsLoadedToken() {
  return { type: SET_IS_LOADED_TOKEN };
}

export function loadUsersSuccess(users) {
  return { type: LOAD_USERS_SUCCESS, payload: users };
}

export function loadApprovalUsersSuccess(users) {
  return { type: LOAD_APPROVAL_USERS_SUCCESS, payload: users };
}

export function checkApprovalUsersLoading() {
  return { type: CHECK_APPROVAL_USERS_LOADING };
}

export function checkUsersLoading() {
  return { type: CHECK_USERS_LOADING };
}

export function checkAuthenticateUserLoading() {
  return { type: LOAD_AUTHENTICATE_LOADING };
}

export function checkAuthenticateUserSuccess(user) {
  return { type: LOAD_AUTHENTICATE_SUCCESS, payload: user };
}

/**
 * @param {number} errorStatus
 * @returns {{payload: number, type: string}}
 */
export function checkAuthenticateUserFail(errorStatus) {
  return { type: LOAD_AUTHENTICATE_FAIL, payload: errorStatus };
}

export function loadUserSuccess(user) {
  return { type: LOAD_USER_SUCCESS, payload: user };
}

export function createUserSuccess(user) {
  return { type: CREATE_USER_SUCCESS, payload: user };
}

export function checkAdminDataLoaded() {
  return { type: CHECK_ADMIN_DATA_LOADED };
}

export function loadAdminDataSuccess(data) {
  return { type: LOAD_ADMIN_DATA_SUCCESS, payload: data };
}

export function checkUpdateAdminLoaded(loading = true) {
  return { type: CHECK_UPDATE_ADMIN_LOADED, payload: loading };
}

export function updateAdminSuccess(data) {
  return { type: UPDATE_ADMIN_SUCCESS, payload: data };
}

export function onboardAdminsSuccess(data) {
  return { type: ONBOARD_USERS_SUCCESS, payload: data };
}

export function setAdminSearchValue(data) {
  return { type: SET_ADMIN_SEARCH_VALUE, payload: data };
}

export function setLastAdminSearchRequestTime(data) {
  return { type: SET_LAST_ADMIN_SEARCH_REQUEST_TIME, payload: data };
}

export function setUserSettings(
  settingType: DTO.UserSettingsType,
  data: DTO.MemoSectionAppearanceData
) {
  return { type: SET_USER_SETTINGS, payload: { settingType, data } };
}

export function setUserSettingsLoading(
  settingType: DTO.UserSettingsType,
  loading: boolean
) {
  return { type: SET_USER_SETTINGS_LOADING, payload: { settingType, loading } };
}

export function loadApprovalUsers() {
  return (dispatch) => {
    dispatch(checkApprovalUsersLoading());
    return userApi
      .getApprovalUsers()
      .then((users) => dispatch(loadApprovalUsersSuccess(users)))
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}

const callAndResolveLoadUsersApi = (
  api: () => Promise<DTO.UserData>,
  dispatch: any
) => {
  dispatch(checkUsersLoading());
  return api()
    .then((users) => dispatch(loadUsersSuccess(users)))
    .catch((error) => {
      dispatch(handleErrors(error));
    });
};

export function loadUsers() {
  return (dispatch) =>
    callAndResolveLoadUsersApi(userApi.getAllUsers, dispatch);
}

export function loadAiUsers() {
  return (dispatch) => {
    dispatch(checkUsersLoading());
    return userAiApi
      .getAiUsers()
      .then((users) => dispatch(loadUsersSuccess(users)))
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}

export function loadUsersForSharing() {
  return (dispatch, getState) => {
    const me = getState().user.currentUser as DTO.CurrentUser;
    const api = checkUserRole(me.user_type).globalAdmin
      ? userApi.getAllUsers
      : userApi.getUsersForSharing;
    return callAndResolveLoadUsersApi(api, dispatch);
  };
}

export function loadUser(userIdUrl) {
  return (dispatch) => {
    return userApi
      .getUser(userIdUrl)
      .then((user) => {
        return dispatch(loadUserSuccess(user));
      })
      .catch((error) => {
        dispatch(handleErrors(error));
      });
  };
}

export function getCurrentUser() {
  return (dispatch) => {
    dispatch(checkAuthenticateUserLoading());
    const checkTokenCallback = async () => {
      dispatch(setIsLoadedToken());
      setToLocalStorage(PREVENT_LOAD_DASHBOARD, true);
      await dispatch(getEnvironmentConfig());
    };

    return userApi
      .checkAuthenticateUser(checkTokenCallback)
      .then((user) => {
        if (!_.isEmpty(user)) {
          dispatch(checkAuthenticateUserSuccess(user));
        }
        return user;
      })
      .catch((error) => {
        dispatch(checkAuthenticateUserFail(error?.response?.status));
        dispatch(handleErrors(error));
      });
  };
}

export function deleteUserSuccess(cat) {
  return { type: DELETE_USER_SUCCESS, cat };
}

export function deleteAdminSuccess(cat) {
  return { type: DELETE_ADMIN_SUCCESS, cat };
}

export function deleteUser(user, passError = false) {
  return (dispatch, getState) => {
    const userRole = getState().user.currentUser
      .user_type as DTO.CurrentUser['user_type'];
    const isAiAdmin = checkUserRole(userRole).aiAdmin;
    const request = isAiAdmin
      ? userAiApi.removeAdmin(user.user_id)
      : userApi.deleteUser(user);

    return request
      .then(() => {
        return dispatch(deleteUserSuccess(user));
      })
      .catch((error) => {
        if (!passError) {
          handleErrors(error);
        }
        throw error;
      });
  };
}

export function deleteUsers(users: DTO.CommonUserType[], passError = false) {
  return (dispatch) => {
    return userApi
      .deleteUsers(users)
      .then(() => {
        return dispatch(deleteUserSuccess(users));
      })
      .catch((error) => {
        if (!passError) {
          handleErrors(error);
        }
        throw error;
      });
  };
}

export function deleteAdmin(admin, passError = false) {
  return (dispatch) => {
    return userApi
      .deleteAdmin(admin)
      .then(() => {
        return dispatch(deleteUserSuccess(admin));
      })
      .catch((error) => {
        if (!passError) {
          handleErrors(error);
        }
        throw error;
      });
  };
}

export function getAdminData() {
  return (dispatch, getState) => {
    const lastRequestTime = Date.now();
    const userRole = getState().user.currentUser
      .user_type as DTO.CurrentUser['user_type'];
    const isAiAdmin = checkUserRole(userRole).aiAdmin;

    // We have access to admin users with two roles, global admin and ai admin, so we need to call different request
    const request = isAiAdmin
      ? userAiApi.getAdminData()
      : userApi.getAdminData();

    dispatch(setLastAdminSearchRequestTime(lastRequestTime));
    dispatch(checkAdminDataLoaded());

    return request
      .then((data) => {
        return dispatch(loadAdminDataSuccess({ data, lastRequestTime }));
      })
      .catch((error) => {
        handleErrors(error);
      });
  };
}

export function updateAdmin(
  admin,
  showSuccessMessage = false,
  errorCb?: (err: any) => boolean
) {
  return (dispatch) => {
    dispatch(checkUpdateAdminLoaded());
    return userApi
      .updateAdmin(admin)
      .then((data) => {
        if (showSuccessMessage) {
          toastSuccess(
            resources.en.translations['global admin toast role changed']
          );
        }
        return dispatch(updateAdminSuccess(data));
      })
      .catch((error) => {
        if (!errorCb?.(error)) {
          handleErrors(error);
        } else {
          dispatch(checkUpdateAdminLoaded(false));
        }
      });
  };
}

export function getOPMAdminData(query) {
  return (dispatch) => {
    dispatch(checkAdminDataLoaded());
    return userApi
      .getOPMAdminData(query)
      .then((data) => dispatch(loadAdminDataSuccess({ data })))
      .catch((error) => {
        handleErrors(error);
      });
  };
}

export const acceptTermsOfUse = (id: number) => (dispatch) => {
  return userApi
    .acceptTermsOfUse(id)
    .then(() => {
      return dispatch(getCurrentUser());
    })
    .catch((error) => {
      handleErrors(error);
      throw error;
    });
};

export const getUserSettings =
  (settingType: DTO.UserSettingsType) => (dispatch) => {
    dispatch(setUserSettingsLoading(settingType, true));
    return userApi
      .getUserSettings(settingType)
      .then((data) => {
        dispatch(setUserSettings(settingType, data));
        return data;
      })
      .catch((err) => {
        dispatch(setUserSettingsLoading(settingType, false));
        handleErrors(err);
      });
  };
