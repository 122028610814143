import fileDownload from 'react-file-download';

import qs from 'querystring';

import { AxiosPromise } from 'axios';

import { axiosService } from '../axios.service';
import { ImportAiTreePayload } from '../../store/treeVersion/treeVersion.interface';

class fileApi {
  static getMemoAttachment(attachmentId: string): Promise<Blob> {
    return axiosService
      .instance({
        url: `attachment/${attachmentId}`,
        responseType: 'blob'
      })
      .then(({ data }) => data);
  }

  static memoDownloadDocument(
    body: DTO.GenerateMemoFileData
  ): AxiosPromise<Blob> {
    return axiosService.instance({
      method: 'POST',
      url: `/memo/generate`,
      responseType: 'blob',
      data: body
    });
  }

  static getMemoFiles(memoId) {
    return axiosService
      .instance({
        url: `file/?memo=${memoId}`
      })
      .then(({ data }) => data);
  }

  static getFileById(fileId: string): Promise<Blob> {
    return axiosService
      .instance({
        url: `file/${fileId}`,
        responseType: 'blob'
      })
      .then(({ data }) => data);
  }

  static getAllFiles() {
    return axiosService
      .instance({
        url: 'file/'
      })
      .then(({ data }) => data);
  }

  static exportFile(tree) {
    return axiosService
      .instance({
        method: 'post',
        url: 'tree/export/',
        data: tree,
        responseType: 'blob'
      })
      .then((response) => {
        const blob = new Blob([response.data]);
        fileDownload(blob, 'Exported_Tree.xlsx');
        return response;
      });
  }

  static downloadMemoAiTreeVersion({
    tree_version_id,
    tree_version_number,
    tree_name
  }: DTO.AITreeVersionDownloadProps) {
    return axiosService
      .instance({
        method: 'post',
        url: `ai/admin/tree/version/export`,
        data: tree_version_id,
        responseType: 'blob'
      })
      .then((response) => {
        const blob = new Blob([response.data]);
        const fileName = `${tree_name}-DecisionTree_ver.${tree_version_number}.xlsx`;
        fileDownload(blob, fileName);
        return response;
      });
  }

  static importFile(tree) {
    const formData = new FormData();
    formData.append('file', tree);
    return axiosService
      .instance({
        method: 'post',
        url: 'tree/import',
        data: formData
      })
      .then(({ data }) => data);
  }

  static importAiTree({ treeId, file }: ImportAiTreePayload): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('treeId', treeId);

    return axiosService
      .instance({
        method: 'post',
        url: 'ai/admin/tree/version/import',
        data: formData
      })
      .then(({ data }) => data);
  }

  static getSummaryReport(startDate: string, endDate: string) {
    return axiosService
      .instance({
        method: 'get',
        url: 'report/summary',
        params: { startDate, endDate },
        responseType: 'blob',
        paramsSerializer: (parameters) => {
          return qs.stringify(parameters);
        }
      })
      .then(({ data }) => data);
  }
}

export default fileApi;
