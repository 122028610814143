import { TFunction } from 'react-i18next';

import { MultiSelectOption } from 'components/common/ThemedMultiSelect/ThemedMultiSelect.helpers';
import { USER_PATH } from 'shared/constants/routesConstants';

export interface MenuItem {
  key: string;
  name: string;
  url: USER_PATH;
  icon: JSX.Element;
}

export const getCookie = (cname: string): string => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (
  name: string,
  value: string | number,
  date?: Date
): void => {
  let expires = '';
  if (date) {
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/;secure`;
};

export const clearCookie = (key: string): void => {
  document.cookie = `${key}=;path=/;secure`;
};

export const getWindow = (): typeof window & { APP_CONFIG: MEMO.AppConfig } =>
  window as any;

export const getAllAPIs = (): string[] => {
  const appConfig = getWindow().APP_CONFIG;
  if (window.location.hostname === 'localhost') {
    return [appConfig.API_URL];
  }
  const apisAndHubs = Object.values(appConfig.COUNTRY_URLS);
  const uniqueApis = [...new Set(apisAndHubs.map(({ API }) => API))];
  return uniqueApis;
};

export const handleDataSearch = (
  array: Record<string, any>[],
  value: string
) => {
  return array.filter((obj) => {
    return Object.keys(obj).some((key) => {
      return String(obj[key]).toLowerCase().includes(value.toLowerCase());
    });
  });
};

export const sortAlphabeticallyBy = (key: string) => {
  return (a, b) => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  };
};

export const convertBytesToMb = (size: number): number => {
  return size / Math.pow(1024, 2);
};

export const getCountriesOptions = (
  selectedCountries: string[],
  countries: DTO.Countries
): MultiSelectOption[] => {
  const keys = Object.keys(countries);
  return keys
    .map((key: string) => {
      return {
        text: countries[key],
        value: key,
        selected: selectedCountries?.includes(key)
      };
    })
    .sort(sortAlphabeticallyBy('text'));
};

export const isUndefined = (value) => value === undefined;
export const isLocalhost = () => window.location.hostname === 'localhost';
export const isTruncated = (el) => el.clientWidth < el.scrollWidth;

export const truncateFormattedText = (
  text: string,
  targetElementWidth: number
) => {
  let truncateIndex: number = text.length;
  for (let i = 0; i < text.length; i++) {
    if (text.charAt(i) === '\n') {
      truncateIndex = i;
      break;
    }
  }

  const span = document.createElement('span');
  document.body.appendChild(span);
  span.style.whiteSpace = 'nowrap';
  span.style.fontSize = '14px';

  let lastCharInTargetElementIndex = text.length;
  for (let i = 0; i < lastCharInTargetElementIndex; ++i) {
    span.innerHTML += text[i];
    if (span.getBoundingClientRect().width > targetElementWidth) {
      lastCharInTargetElementIndex = i - 1;
      break;
    }
  }

  document.body.removeChild(span);
  if (lastCharInTargetElementIndex < truncateIndex) {
    truncateIndex = lastCharInTargetElementIndex;
  }

  return `${text.substring(0, truncateIndex).trim()}${
    text.length !== truncateIndex ? '...' : ''
  }`;
};

export const checkMemoFileScanStatus = (
  status?: DTO.MemoFileScanStatus
): Record<
  DTO.MemoFileScanStatus | 'errorOrInfected' | 'inProgressOrNotScanned',
  boolean
> => {
  const scanInProgress = status === 'scanInProgress';
  const notScanned = status === 'notScanned';
  const infected = status === 'infected';
  const scanError = status === 'scanError';
  const serviceUnavailable = status === 'serviceUnavailable';

  return {
    notScanned,
    scanInProgress,
    scanPassed: status === 'scanPassed',
    infected,
    scanError,
    serviceUnavailable,
    errorOrInfected: infected || scanError || serviceUnavailable,
    inProgressOrNotScanned: scanInProgress || notScanned
  };
};

export const getFileScanStatusDescription = (
  status?: DTO.MemoFileScanStatus | DTO.AITreeVersionStatus
) => {
  switch (status) {
    case 'infected':
      return 'fileInfected';
    case 'scanError':
      return 'fileScanError';
    case 'serviceUnavailable':
      return 'antivirusServiceUnavailable';
    case 'failed':
      return 'version status failed';
    case 'inProgress':
    case 'new':
      return 'labelInProgress';
    default:
      return '';
  }
};

export const canDeleteUser = ({
  user,
  me
}: {
  user: DTO.CommonUserType;
  me: DTO.CurrentUser;
}): {
  canDelete: boolean;
  getReason?: (t: TFunction) => string;
} => {
  const myRoleIs = checkUserRole(me.user_type);

  if (!user.user_id || myRoleIs.user) {
    return { canDelete: false };
  }

  if (myRoleIs.aiAdmin) return { canDelete: true };

  if (checkIsItMe(user, me)) {
    return {
      canDelete: false,
      getReason: (t) => {
        return myRoleIs.countryAdmin
          ? t('noPermissionsToDelete', { name: t('countryAdmin') })
          : t('canNotDeleteYourselfFrom', {
              name: t(myRoleIs.globalAdmin ? 'adminList' : 'userList')
            });
      }
    };
  }

  const userRoleIs = checkUserRole(user.user_type);

  if (myRoleIs.countryAdmin && !userRoleIs.user) {
    return {
      canDelete: false,
      getReason: (t) =>
        t('noPermissionsToDelete', {
          name: t(userRoleIs.countryAdmin ? 'countryAdmin' : 'globalAdmin')
        })
    };
  }

  return { canDelete: true };
};

export const checkUserRole = (
  role?: DTO.UserType | null
): Record<
  Exclude<DTO.UserType, 'admin'> | 'someAdmin' | 'countryAdmin',
  boolean
> => {
  const countryAdmin = role === 'admin';
  const globalAdmin = role === 'globalAdmin';
  const aiAdmin = role === 'aiAdmin';
  const user = !role || role === 'user';

  return {
    countryAdmin,
    aiAdmin,
    globalAdmin,
    user,
    someAdmin: countryAdmin || globalAdmin || aiAdmin
  };
};

export const checkIsItMe = (
  user: DTO.CommonUserType,
  me: DTO.CurrentUser
): boolean => {
  return me.user_id === user.user_id || me.user_email === user.user_email;
};
